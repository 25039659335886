/**
 * Nosy Dsicipline
 *
 * @export Dsicipline
 */
import {
    Box, Button, Center, Container,
    Flex,
    Heading, LinkBox, LinkOverlay, SimpleGrid,
    Stack, Text,
    Tooltip,
    useBreakpointValue,
    useToken
} from '@chakra-ui/react'
import { PortableText } from '@portabletext/react'
import { graphql } from 'gatsby'
import React from 'react'
import BackLink from '../../components/back-link/back-link'
import Image from '../../components/image/image'
import Layout from '../../components/layout/layout'
import Link from '../../components/link/link'
import SEO from '../../components/seo/seo'
import { ctaSerializer, headingSerializer, leadSerializer } from '../../serializers'
import { mapEdgesToNodes } from '../../utils/helpers'

// Query variables passed in from pageContext
export const query = graphql`
    query TEMPLATE_DISCIPLINE_QUERY($id: String!) {
        sanityDiscipline(id: { eq: $id }) {
            title
            heading: _rawHeading(resolveReferences: { maxDepth: 10 }),
            seo: _rawSeo(resolveReferences: { maxDepth: 10 })
            excerpt: _rawExcerpt(resolveReferences: { maxDepth: 10 })
            gallery {
                images {
                    ...ImageWithPreview
                }
            }
            cta: _rawCta(resolveReferences: { maxDepth: 10 })
        }
        allSanityDiscipline(
            filter: { parentDiscipline: { id: { eq: $id } } }
            sort: { fields: title, order: ASC }
        ) {
            edges {
                node {
                    title
                }
            }
        }
        allSanityWork(
            filter: {
                discipline: { elemMatch: { id: { eq: $id } } }
                _id: { regex: "/^(?!drafts).*/" }
            }
            sort: { 
                fields: _createdAt
                order: DESC
            }
            limit: 4
        ) {
            edges {
                node {
                    id
                    title
                    slug {
                        current
                    }
                    heading
                    featureImage {
                        ...ImageWithPreview
                    }
                    disciplines: _rawDiscipline(
                        resolveReferences: { maxDepth: 10 }
                    )
                }
            }
        }
    }
`

const Discipline = ( props ) => {
    // console.log( '🦄 ~ file: discipline.js ~ line 38 ~ DisciplineIndex ~ props', props )

    const {
        data: {
            sanityDiscipline: {
                title = '',
                seo: { 
                    seoTitle = '', 
                    seoDescription = ''
                },
                heading = [],
                excerpt = [],
                gallery = {},
                cta = []
            },
            allSanityDiscipline = [],
            allSanityWork = []
        }
    } = props
    
    // Map edges to nodes
    let childDisciplines = mapEdgesToNodes( allSanityDiscipline )
    let work = mapEdgesToNodes( allSanityWork )

    // Destructure/unwrap work nodes array to play nice with WorkRow reusable component
    work = work.map( ( { node } ) => node )

    // Destructure childDisciplines to flat array of strings
    childDisciplines = childDisciplines.map( ( { node } ) => node.title )

    // Destructure Gallery images
    const { images = [] } = gallery

    // Set image sizes for different viewport sizes
    const imageWidths = useBreakpointValue( {
        base: 447, 
        sm: 356,
        md: 300,
        lg: 389,
        xl: 443
    } )

    // Set gallery image sizes for different viewport sizes
    const galleryImageWidths = useBreakpointValue( {
        base: 540,  // 0-48em
        md: 920,    // 48em-80em,
        xl: 1080,   // 80em+
    } )

    // Pluck value from theme for Webkit Stroke value
    const [gray100] = useToken( 'colors', ['gray.100'], ['white'] )

    return (
        <Layout>
            <SEO
                title={`${seoTitle} | Disciplines`}
                description={seoDescription} />

            <Container maxWidth='6xl' mb={20}>
                <Tooltip
                    label='See all disciplines'
                    aria-label='See all disciplines'
                    fontSize='md'
                    placement='top'
                    backgroundColor='brand_primary.700'
                    color='white'
                    arrowShadowColor='brand_primary.700'>
                    <Box as='span' width='32px' height='32px' display='block'>
                        <BackLink to='/disciplines/' />
                    </Box>
                </Tooltip>
                <Center flexDirection='column'>
                    <Heading
                        as='h1'
                        fontSize='md'
                        fontWeight='normal'
                        color='gray.500'
                        borderColor='gray.500'
                        borderWidth='1px'
                        borderRadius='full'
                        px={6}
                        py={3}>
                        Disciplines
                    </Heading>
                    <Heading
                        fontSize={['3xl', '4xl', '6xl', '7xl']}
                        color='gray.100'
                        textAlign='center'
                        mt={12}
                        mb={[10, 20]}>
                        {title}
                    </Heading>
                </Center>
            </Container>

            <Container maxWidth='6xl' mb={20}>
                <Box maxWidth='3xl'>
                    <PortableText
                        value={heading}
                        components={headingSerializer} />
                    <Box maxWidth='md'>
                        <PortableText
                            value={excerpt}
                            components={leadSerializer} />
                    </Box>
                </Box>
            </Container>

            <Container maxWidth='6xl' mb={[20, 40]}>
                <SimpleGrid my={10} columns={[1, 2]} spacing={[4, 6, 8, 10]}>
                    {work.map( ( node ) => (
                        <LinkBox 
                            key={node.id}
                            role='group'>
                            <Stack direction='column' pb={10} height='full'>
                                {node.featureImage && (
                                    <Image
                                        imageData={node.featureImage}
                                        loading='eager'
                                        width={imageWidths}
                                        height={400}
                                        sx={{
                                            width: 'full'
                                        }} />
                                )}
                                <LinkOverlay
                                    as={Link}
                                    to={`/work/${node.slug.current}/`}>
                                    <Heading
                                        as='h2'
                                        color='gray.100'
                                        transition='all 0.343s'
                                        _groupHover={{
                                            color: 'brand_secondary.500'
                                        }}
                                        fontSize={['lg', 'xl', '2xl', '3xl']}
                                        pt={4}>
                                        {node.title}
                                    </Heading>
                                    <Text
                                        my={4}
                                        fontSize='md'
                                        maxWidth='2xl'
                                        mx='auto'
                                        color='gray.400'>
                                        {node.heading}
                                    </Text>
                                </LinkOverlay>
                            </Stack>
                        </LinkBox>
                    ) )}
                </SimpleGrid>
                <Center>
                    <Button
                        as={Link}
                        to='/work/'
                        size='lg'
                        variant='brand-outline'>
                        View work
                    </Button>
                </Center>
            </Container>


            <Container maxWidth='6xl'>
                <Heading
                    as='h3'
                    fontSize={['3xl', '6xl', '7xl']}
                    fontFamily='heading'
                    color={null}
                    position='relative'
                    zIndex={5}
                    sx={{
                        WebkitTextFillColor: 'transparent',
                        WebkitTextStrokeWidth: 1,
                        // WebkitTextStrokeColor: 'brand_grayscale.white',
                        WebkitTextStrokeColor: gray100,
                        textTransform: 'capitalize'
                    }}>
                    {childDisciplines.join( ', ' )}
                </Heading>
            </Container>
            
            
            <Container maxWidth='full' mt={-8}>
                <Flex
                    height='max'
                    overflow='hidden'
                    position='relative'
                    _after={{
                        content: '""',
                        position: 'absolute',
                        zIndex: 4,
                        width: 'full',
                        height: 'full',
                        background:
                        'linear-gradient(90deg, rgba(0,3,9,1) 0%, rgba(0,0,0,0) 20%, rgba(15,15,15,0) 80%, rgba(0,3,9,1) 100%)'
                    }}>
                    <SimpleGrid mb={20} columns={[1, 2, 3]} spacing={[4, 6]}>
                        {images.map( ( image, index ) => {
                            return (
                                <Image  
                                    key={index} 
                                    imageData={image} 
                                    width={galleryImageWidths}
                                    height={430}
                                    objectFit='cover'
                                    position='relative'
                                    zIndex={2} />
                            )

                        } )}
                    </SimpleGrid>
                </Flex>
            </Container>

            <Container maxWidth='6xl' my={[20, 40]}>
                <Stack direction='column' mb={20}>
                    <PortableText
                        value={cta}
                        components={ctaSerializer} />
                </Stack>
                <Button
                    as={Link}
                    to='/contact/'
                    width='min'
                    size='lg'
                    variant='brand-outline'>
                Contact us
                </Button>
            </Container>
        </Layout>
    )
}

export default Discipline
